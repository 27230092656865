import './Checkbox.scss'

const Checkbox = (props: { label: string }) => {

  return (
    <label className="checkbox">
      <span className="checkbox__input">
        <input type="checkbox" name="checkbox" />
        <span className="checkbox__control">
          <svg xmlns="http://www.w3.org/2000/svg" width="24.219" height="21.138" viewBox="0 0 24.219 21.138">
            <path id="Path_14939" data-name="Path 14939" d="M92.117,122.526a2.54,2.54,0,0,1-2.138-1.069L83.326,112.9a2.709,2.709,0,1,1,4.277-3.326l4.514,5.821L102.1,102.45a2.709,2.709,0,1,1,4.277,3.326L94.255,121.457A3.107,3.107,0,0,1,92.117,122.526Z" transform="translate(-82.739 -101.388)" fill="#51aabc" />
          </svg>
        </span>
      </span>
      <span className="radio__label">{props.label}</span>
    </label>
  );
};

export default Checkbox;
