import UserContextProvider from '../utils/UserContext';
import './EmbeddedLayout.scss';

function EmbeddedLayout(props: { children: any, className?: string, menuOpen?: boolean, isAuthenticated: boolean, location: any }) {

  return (<>
    <UserContextProvider location={props.location}>
      <div className={`ks-embedded hp-v3  hp-layoutV2 ${props.className}`}>
        <div className='ks-content' >
          {props.children}
        </div>
      </div>
    </UserContextProvider>
  </>);
}

export default EmbeddedLayout;
