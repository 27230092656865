import { useContext, useEffect, useState } from 'react';
import './Member.scss';
import Modal from 'react-modal';
import ProfilForm from './MemberForm';
import { ReactComponent as HoopowLogo } from '../../assets/hoopow-logo.svg';
import { ReactComponent as TopBanner } from '../../assets/hoopow-topbanner.svg';
import { ReactComponent as CloseBtn } from '../../assets/close-btn.svg';
import { ReactComponent as CBHidden } from '../../assets/cb_hidden.svg';
import { ReactComponent as GiftBox } from '../../assets/giftbox.svg';
import { useHistory } from 'react-router-dom';
import UserDS from '../../datastore/UserDS';
import { stripe } from '../utils/Stripe';
import { TUserPlan } from '../../types';
import Plans from '../elements/Plans';
import FAQ from '../elements/FAQ';
import { ClipLoader } from 'react-spinners';
import { useGA4React } from 'ga-4-react';
import BaseDS from '../../datastore/BaseDS';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { UserContext } from '../utils/UserContext';

const planLabel: any = {
  no_plan: "Aucun",
  monthly_plan: 'Abonnement mensuel',
  yearly_plan: 'Abonnement annuel',
  yearly_plus_plan: 'Abonnement++ annuel'
}

const contractNoRenewal: any = {
  'free_plan': "d’essai gratuit",
  'month_gift_plan': "d'utilisation",
  'quarter_year_gift_plan': "d'utilisation",
  'half_year_gift_plan': "d'utilisation",
  'year_gift_plan': "d'utilisation",
};

function Member(props: any) {
  const ga: any = useGA4React();
  const urlParams = new URLSearchParams(props.location.search);
  const showPlans: any = urlParams.get('showPlans');
  const [rootUser, setRootUser] = useState<any>();
  const [couponId, setCouponId] = useState<string>('');
  const [couponStatus, setCouponStatus] = useState<string>('none');
  const [applyingCoupon, setApplyingCoupon] = useState<boolean>(false);

  const [loadedPlan, setLoadedPlan] = useState<boolean>(false);
  const [userPlan, setUserPlan] = useState<TUserPlan>();
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(showPlans === '1');
  const [loading, setLoading] = useState<boolean>(false);
  const [confirmationCancellationIsOpen, setConfirmationCancellationIsOpen] = useState<boolean>(false);
  const [disabled, setDisable] = useState<boolean>(false)
  const history = useHistory();
  const sessionId: any = urlParams.get('session_id');
  const modalElement: any = document.getElementById('mainContentScreen');

  const { isUserAuthenticated, userInfo } = useContext(UserContext);

  useEffect(() => {
    if (isUserAuthenticated && userInfo) {
      setRootUser({
        name: userInfo.attributes.name,
        email: userInfo.attributes.email,
      });
    } else {
      if (showPlans) {
        setRootUser(undefined);
      } else if (isUserAuthenticated === false) {
        history.push('/');
      }
    }
  }, [userInfo, isUserAuthenticated, showPlans, history]);

  useEffect(() => {
    setModalIsOpen(showPlans === '1');
  }, [showPlans]);

  useEffect(() => {
    if (sessionId && !loadedPlan) {
      setLoadedPlan(true);
      UserDS.getUserPlan(sessionId).then((plan: TUserPlan) => {
        setUserPlan(plan);
      })
    } else if (isUserAuthenticated && !loadedPlan) {
      UserDS.getUserPlan().then((plan: TUserPlan) => {
        setUserPlan(plan);
      })
    }
  }, [sessionId, isUserAuthenticated, loadedPlan]);

  const getDayBeforeExpiration = (endTimestamp: number) => {
    if (userPlan) {
      const endDate = new Date();
      endDate.setTime((endTimestamp * 1000));
      const today = new Date(new Date().toDateString());
      const diffInTime = endDate.getTime() - today.getTime();
      const nbDaysBeforeEnd = Math.floor(diffInTime / (1000 * 3600 * 24));
      return nbDaysBeforeEnd > 0 ? nbDaysBeforeEnd : 0;
    } else {
      return 0;
    }
  }

  const getFormattedEndDate = (endTimestamp: number): string => {
    if (userPlan) {
      const endDate = new Date();
      endDate.setTime((endTimestamp * 1000));
      return endDate.toLocaleString("fr-FR", { day: '2-digit', month: 'long', year: 'numeric' });
    } else {
      return '';
    }
  }

  const cancelPlan = () => {
    setLoading(true);
    UserDS.cancelPlan().then((plan: TUserPlan) => {
      setUserPlan(plan);
      setConfirmationCancellationIsOpen(false);
      setLoading(false);
    })
  }

  const dayBeforeExpiration = userPlan ? getDayBeforeExpiration(userPlan.periodEnd) : -1;

  const closeBtnHandler = (legacyAction: Function) => {
    history.push('/');
  }

  useEffect(() => {
    // Configuring the notification 
    toast.configure();
  }, []);

  const notifySuccess = (userData: any) => {
    toast.success(`Votre coupon a bien été pris en compte et votre compte expire dans ${getDayBeforeExpiration(userData.periodEnd)} ${getDayBeforeExpiration(userData.periodEnd) < 2 ? 'jour' : 'jours'}`, { position: toast.POSITION.TOP_CENTER });
  }
  const notifyFail = () => {
    toast.error(`Le coupon ${couponId} a déjà été utilisé.`, { position: toast.POSITION.TOP_CENTER });
  }

  return (
    <div className="hp-member">
      <CloseBtn className='hp-closeBtn ks-clickable' onClick={() => history.push('/')} />
      {modalElement && modalIsOpen && (
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={() => closeBtnHandler(() => setModalIsOpen(false))}
          portalClassName={'ReactModalPortal'}
          contentLabel="Member Plans"
          parentSelector={() => modalElement}
          appElement={modalElement}
        >
          <CloseBtn className='hp-closeBtn ks-clickable' onClick={() => closeBtnHandler(() => setModalIsOpen(false))} />
          <HoopowLogo className="hp-member-logo" />
          <div className='hp-member-plans-faq'>
            <Plans />
            <FAQ />
          </div>
        </Modal>
      )}
      {modalElement && confirmationCancellationIsOpen && (
        <Modal
          isOpen={confirmationCancellationIsOpen}
          onRequestClose={() => setConfirmationCancellationIsOpen(false)}
          portalClassName={'ReactModalPortal'}
          contentLabel="Member Plans"
          parentSelector={() => modalElement}
          appElement={modalElement}
        >
          <CloseBtn className='hp-closeBtn ks-clickable' onClick={() => { setConfirmationCancellationIsOpen(false) }} />
          <HoopowLogo className="hp-member-logo" />
          <div className='hp-member-cancel-confirmation ks-boxShadow'>
            <div>Êtes-vous sûr de vouloir annuler votre abonnement ?</div>
            <div className='hp-member-cancel-btn'>
              <button className='mainBtn hp-plan-cancel-okBtn' disabled={loading} onClick={() => cancelPlan()} >OUI</button>
              <button className='mainBtn hp-plan-cancel-noBtn' disabled={loading} onClick={() => setConfirmationCancellationIsOpen(false)} >NON</button>
            </div>

          </div>
        </Modal>
      )}
      <div className='hp-topBanner hp-connected'>
        <TopBanner />
      </div>
      <HoopowLogo className="hp-member-logo" />
      <div className="hp-member-title" >MON ESPACE PERSONNEL</div>
      <div className="hp-member-boxes">
        <div className='hp-memberDetails ks-boxShadow'>
          <ProfilForm user={rootUser ? rootUser : {}} history={history} />
        </div>
        <div className='hp-memberBilling'>
          <div className={'hp-member-contract ks-boxShadow' + (userPlan && (contractNoRenewal[userPlan.contractType] || userPlan.status !== 'active') ? ' hp-free-period' : '')}>
            {userPlan ? (<>
              {(userPlan.status === 'active') ? (<>
                {contractNoRenewal[userPlan.contractType] ? (<>
                  <span>Il vous reste {dayBeforeExpiration} {dayBeforeExpiration < 2 ? 'jour' : 'jours'} {contractNoRenewal[userPlan.contractType]}</span>
                  <button className='mainBtn' disabled={loading} onClick={() => {
                    if (ga) ga.event('platform', "Subscribe before expiration", 'engagement');
                    setModalIsOpen(true)
                  }} >S’ABONNER</button>
                </>) : (<>
                  <span className='hp-member-contract-title'>VOTRE TYPE D’ABONNEMENT</span>
                  <span className='hp-member-contract-type'>{planLabel[userPlan.contractType]}</span>
                  {userPlan.contractType !== 'no_plan' && (
                    <>
                      {userPlan.cancelAtPeriodEnd ? (<>
                        <span className='hp-member-contract-desc'>Votre abonnement s'arrêtera le <label>{getFormattedEndDate(userPlan.periodEnd)}</label></span>
                      </>) : (<>
                        <span className='hp-member-contract-desc'>Votre prochain paiement de <label>{userPlan.price / 100}€</label> aura lieu le <label>{getFormattedEndDate(userPlan.periodEnd)}</label></span>
                        <button className='mainBtn' disabled={loading} onClick={() => {
                          if (ga) ga.event('platform', "Cancellation", 'engagement');
                          setConfirmationCancellationIsOpen(true)
                        }} >ANNULER L’ABONNEMENT</button>
                      </>)}
                    </>)}
                </>)
                }
              </>) : (<>
                <span>Votre abonnement a expiré.</span>
                <button className='mainBtn' onClick={() => {
                  if (ga) ga.event('platform', "Subscribe after expiration", 'engagement');
                  setModalIsOpen(true)
                }} >S’ABONNER</button>
              </>)}
            </>) : (<div className='hp-planLoader'><ClipLoader /></div>)}
          </div>
          <div className='hp-member-cb ks-boxShadow'>
            <span className='hp-member-cb-title'>MÉTHODE DE PAIEMENT</span>
            <CBHidden />

            <button className='mainBtn' disabled={loading || !userPlan?.customerId || !userPlan?.subscriptionId || disabled} onClick={async () => {
              setLoading(true);
              const dataSession = await UserDS.createSessionUpdateCB();
              await stripe.redirectToCheckout({
                sessionId: dataSession.sessionId
              });
              setLoading(false);
            }}>MODIFIER</button>
          </div>
          <div className='hp-member-gift-panels'>
            <div className='hp-member-gift-code ks-boxShadow'>
              <textarea placeholder='TAPEZ VOTRE CODE PROMO ICI' onChange={(e) => setCouponId(e.target.value)} />
              <button className={`mainBtn ${couponStatus}`} disabled={applyingCoupon || disabled} onClick={() => {
                setApplyingCoupon(true);
                setCouponStatus('En cours');
                BaseDS.applyCoupon(couponId).then((userData: any) => {
                  setCouponStatus('OK')
                  setUserPlan(userData);
                  notifySuccess(userData);
                }).catch(() => {
                  setCouponStatus('Erreur')
                  setDisable(true)
                  notifyFail()
                  setApplyingCoupon(false)
                });
              }} >{couponStatus === 'none' ? 'Valider' : couponStatus}</button>
            </div>
            <div className='hp-member-gift-cards-area ks-boxShadow'>
              <label>ESPACE <br />CARTE CADEAU</label>
              <button className='mainBtn' onClick={() => history.push('/giftspace')}>accéder</button>
              <GiftBox />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Member;
