import './SuccessfulPayment.scss';
import { ReactComponent as SuccessIcon } from '../../assets/v3/payment_success.svg';

const SuccessfulPayment = (props: {action: Function}) => {

  return (
    <div className="hp-successfulPayment">
      <SuccessIcon />
      <div className="hp-successfulPayment__info">VOTRE PAIEMENT A ÉTÉ VALIDÉ !</div>
      <button className="hp-successfulPayment__btn" onClick={() => props.action()}>CONSULTER LE CONTENU</button>
      <div className="hp-successfulPayment__thanks">Merci ! En achetant du contenu vous financez le développement de la plateforme.</div>
    </div>
  );
};

export default SuccessfulPayment;
