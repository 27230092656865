import ApiWrapper from './ApiWrapper';
import { TUserPlan } from '../types'

const apiName = 'ApiGatewayRestApi';

export default class UserDS {

  static getUserPlan = async (sessionId?: string): Promise<TUserPlan> => {
    return ApiWrapper.get(apiName, '/user/plan' + (sessionId ? ('/' + sessionId) : ''));
  };

  static createSession = async (planId: string, email: string, name: string): Promise<any> => {
    return ApiWrapper.post(apiName, '/stripe/session/' + planId, { body: {email, name} });
  };

  static createSessionUpdateCB = async (): Promise<any> => {
    return ApiWrapper.put(apiName, '/stripe/session', { body: {} });
  };

  static cancelPlan = async (): Promise<TUserPlan> => {
    return ApiWrapper.delete(apiName, '/user/plan', { body: {} });
  };
}

