import React from 'react';

import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';

import './App.scss';
import AuthenticationComponent from './components/Authentication';
import HomepageComponent from './components/Homepage';
import CGUComponent from './components/CGU';

import { BrowserRouter as Router, Redirect, Switch } from "react-router-dom";
import { AmplifyAuthenticator } from '@aws-amplify/ui-react';

import MainLayout from './components/MainLayout';
import MemberComponent from './components/pages/Member';
import SubscribeComponent from './components/pages/Subscribe';
import GiftSpaceComponent from './components/pages/GiftSpace';
import EidGiftPageComponent from './components/pages/EidGiftPage';
import GiftCouponComponent from './components/pages/GiftCoupon';
import ContactUsComponent from './components/pages/ContactUs';
import PrivateRoute from './components/elements/PrivateRoute';
import TitledRoute from './components/elements/common/internalrouter/TitledRoute';
import { Auth } from 'aws-amplify';
import LayoutV2 from './components/layout/LayoutV2';
import UserContextProvider from './components/utils/UserContext';
import MainConfigContext from './components/utils/MainConfigContext';
import EmbeddedLayout from './components/layout/EmbeddedLayout';
import CheckoutForm from './components/elements/CheckoutForm';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from "@stripe/stripe-js";
import UserAnnouncement from './components/elements/common/phoneblocked/UserAnnouncement';

function App() {
  const [authState, setAuthState] = React.useState<AuthState>();
  const url = new URL(window.location.href);
  const embedded = url.searchParams.get("embedded");

  if (process.env.REACT_APP_FEATURE_NEW_DESIGN_V3 === '1') document.body.classList.add('bodyV3')
  else document.body.classList.remove('bodyV3')

  React.useEffect(() => {

    if (authState === undefined) {
      Auth.currentAuthenticatedUser({ bypassCache: true }).then((data) => {
        const userGroup = data.signInUserSession.accessToken.payload['cognito:groups'];
        setAuthState(AuthState.SignedIn);
        if (userGroup.indexOf('awlad') > -1) {
          document.location.href = "https://awlad-school.com"
        }
      }).catch((error) => {
        console.log('Anonymous user.', error);
      });
    }

    return onAuthUIStateChange((nextAuthState) => {
      setAuthState(nextAuthState);
      Auth.currentAuthenticatedUser({ bypassCache: true }).then((data) => {
        const userGroup = data.signInUserSession.accessToken.payload['cognito:groups'];
        setAuthState(AuthState.SignedIn);
        if (userGroup.indexOf('awlad') > -1) {
          document.location.href = "https://awlad-school.com"
        }
      }).catch((error) => {
        console.log('Anonymous user.', error);
      });
    });
  }, [authState]);

  const isAuthenticated = () => {
    return authState === AuthState.SignedIn || process.env.REACT_APP_FEATURE_FREEMIUM === '1';
  }

  return (
    <>
      {!embedded && (
        <UserAnnouncement />
      )}
      {authState !== undefined ? (
        <UserContextProvider>
          <MainConfigContext>
            <React.Suspense fallback={<></>}>
              <Router>
                <Switch>
                  <TitledRoute path="/" pageName="Accueil" exact render={(routeProps: any) => Homepage(routeProps, isAuthenticated())} />
                  <TitledRoute path="/cgu" pageName="CGU" exact render={CGU} />
                  <TitledRoute path="/login" pageName="Se connecter" exact render={SignIn} />
                  <TitledRoute path="/signin" pageName="Se connecter" exact render={SignInV2} />
                  <TitledRoute path="/embedded/signup" pageName="S'inscrire'" exact render={SignUpEmbedded} />
                  <TitledRoute path="/embedded/checkout" pageName="Paiement" exact render={CheckoutEmbedded} />
                  <TitledRoute path="/embedded/signin" pageName="Se connecter" exact render={SignInEmbedded} />
                  <TitledRoute path="/coupon/:couponId" pageName='Coupon Cadeau' exact render={GiftCoupon} />
                  <TitledRoute path="/eidgift" pageName='Abonnement Cadeau' exact render={EidGiftPage} />
                  {process.env.REACT_APP_FEATURE_FREEMIUM === '1' ? (
                    <TitledRoute path="/signup" pageName="S'inscrire" exact render={SignUp} />
                  ) : (<Redirect from="/signup" to="/" exact />)}
                  <PrivateRoute path="/cguconnected" pageName="CGU" exact render={(routeProps: any) => CGUConnected(routeProps, isAuthenticated())} authed={isAuthenticated()} />
                  <PrivateRoute path="/member" pageName='Mon espace membre' exact render={Member} authed={isAuthenticated()} />
                  <PrivateRoute path="/subscribe" pageName='Mon espace membre' exact render={Subscribe} authed={isAuthenticated()} />
                  <PrivateRoute path="/giftspace" pageName='Espace Carte Cadeau' exact render={GiftSpace} authed={isAuthenticated()} />
                  <PrivateRoute path="/contactus" pageName='Nous contacter' exact render={ContactUs} authed={isAuthenticated()} />
                </Switch>
              </Router>
            </React.Suspense>
          </MainConfigContext>
        </UserContextProvider>
      ) : (
        <>
          {authState === undefined && (
            <AmplifyAuthenticator />
          )}
        </>)}
    </>
  );
}

export default App;

const SignIn = (props: any) => (
  <MainLayout>
    <AuthenticationComponent {...props} initialAuthState={AuthState.SignIn} />
  </MainLayout>
);

const SignInEmbedded = (props: any) => (
  <EmbeddedLayout {...props}>
    <AuthenticationComponent {...props} initialAuthState={AuthState.SignIn} />
  </EmbeddedLayout>
);

const SignInV2 = (props: any) => (
  <LayoutV2 menuOpen={true} {...props}>
    <AuthenticationComponent {...props} initialAuthState={AuthState.SignIn} />
  </LayoutV2>
);

const SignUp = (props: any) => (
  <LayoutV2 menuOpen={true} {...props}>
    <AuthenticationComponent {...props} initialAuthState={AuthState.SignUp} />
  </LayoutV2>
);

const SignUpEmbedded = (props: any) => (
  <EmbeddedLayout {...props}>
    <AuthenticationComponent {...props} initialAuthState={AuthState.SignUp} />
  </EmbeddedLayout>
);

const CheckoutEmbedded = (props: any) => {
  const url = new URL(window.location.href);
  const productId = url.searchParams.get("productId");
  const itemId = url.searchParams.get("itemId");

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY || '', { locale: 'fr' });
  return (
    <EmbeddedLayout {...props} className="payment-layout">
      <Elements stripe={stripePromise}>
        <CheckoutForm {...props} productId={productId} itemId={itemId} />
      </Elements>
    </EmbeddedLayout>)
};

const Homepage = (props: any, isAuthenticated: boolean) => (
  <HomepageComponent {...props} isAuthenticated={isAuthenticated} />
);

const CGU = (props: any, isAuthenticated: boolean) => (
  <CGUComponent {...props} isAuthenticated={isAuthenticated} />
);

const CGUConnected = (props: any, isAuthenticated: boolean) => (
  <LayoutV2 menuOpen={true} isAuthenticated={isAuthenticated} className='hp-cgu-connected' {...props} >
    <CGUComponent {...props} isAuthenticated={isAuthenticated} isEmbedded={true} />
  </LayoutV2>
);

const Member = (props: any) => (
  <LayoutV2 menuOpen={true} {...props} >
    <MemberComponent {...props} />
  </LayoutV2>
);

const Subscribe = (props: any) => (
  <LayoutV2 menuOpen={true} {...props} >
    <SubscribeComponent {...props} />
  </LayoutV2>
);


const GiftSpace = (props: any, isAuthenticated: boolean) => (
  <LayoutV2 className='hp-giftspace-mainlayout' menuOpen={true} isAuthenticated={isAuthenticated} {...props} >
    <GiftSpaceComponent {...props} isAuthenticated={isAuthenticated} />
  </LayoutV2>
);

const GiftCoupon = (props: any, isAuthenticated: boolean) => (
  <MainLayout className='hp-giftspace-mainlayout' >
    <GiftCouponComponent {...props} />
  </MainLayout>
);

const EidGiftPage = (props: any, isAuthenticated: boolean) => (
  <LayoutV2 className='hp-giftspace-mainlayout' menuOpen={true} isAuthenticated={isAuthenticated} {...props} >
    <EidGiftPageComponent {...props} />
  </LayoutV2>
);

const ContactUs = (props: any, isAuthenticated: boolean) => (
  <LayoutV2 className='hp-giftspace-mainlayout' menuOpen={true} isAuthenticated={isAuthenticated} {...props} >
    <ContactUsComponent {...props} />
  </LayoutV2>
);

