import { useEffect, useState } from "react";
import './SignIn.scss';
import { ReactComponent as CloseBtn } from '../../assets/v2/icon_close_black.svg';
import { Auth } from 'aws-amplify';
import {
  AuthState,
  UI_AUTH_CHANNEL,
  AUTH_STATE_CHANGE_EVENT,
} from "@aws-amplify/ui-components";
import { Hub } from "@aws-amplify/core";
import { useHistory } from "react-router-dom";

const CustomSignIn = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    document.body.classList.remove('hp-body-homepage-notconnected');
  }, []);

  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });
  const handleInputChange = (e: any, prop: string) => {
    setFormData({
      ...formData,
      [prop]: e.target.value
    });
  };
  const signInClick = async () => {
    setLoading(true);
    try {
      await Auth.signIn(formData.email, formData.password);
    }
    catch (error: any) {
      if (error.code && error.code === 'NotAuthorizedException') {
        alert('L’adresse e-mail ou le mot de passe est invalide.');
      } else {
        alert(error.code);
      }
    }
    setLoading(false);
  }

  const forgotPassword = () => {
    Hub.dispatch(UI_AUTH_CHANNEL, {
      event: AUTH_STATE_CHANGE_EVENT,
      message: AuthState.ForgotPassword,
      data: null,
    });
  }

  useEffect(() => {
    document.title = 'HOO POW | Login';
  }, []);

  return (
    <div className="ks-sign-in" slot="sign-in">
      <div className="ks-login">

        <div className="ks-loginTitle">CONNEXION</div>
        <form className='ks-form'>
          <div className="ks-form-group">
            <label className="ks-form-label">Email :</label>
            <input type='email' placeholder="Tapez ici votre email" onChange={(e) => handleInputChange(e, 'email')}
              className="ks-form-control"
            />
          </div>
          <div className="ks-form-group">
            <label className="ks-form-label">Mot de passe :</label>
            <input type="password" placeholder="Tapez ici votre mot de passe" onChange={(e) => handleInputChange(e, 'password')} className="ks-form-control" />
          </div>
        </form>
        <div className="ks-formBtn">
          <button className="mainBtn" type="submit" onClick={() => { signInClick() }} disabled={formData.email === '' || formData.password === '' || loading}>
            SE CONNECTER
          </button>
          <button className="ks-forgetBtn ks-clickable" onClick={() => { forgotPassword() }} disabled={loading}>
            Mot de passe oublié ?
          </button>
        </div>
      </div>
      {process.env.REACT_APP_FEATURE_FREEMIUM === '1' && (
        <CloseBtn className='hp-signin-closeBtn ks-clickable' onClick={() => history.push('/')} />
      )}
    </div>
  );
}
export default CustomSignIn;