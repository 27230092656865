import './AvatarSelector.scss'
import { ReactComponent as ManIcon } from '../../assets/v3/avatar/man-icon.svg';
import { ReactComponent as WomanIcon } from '../../assets/v3/avatar/woman-icon.svg';
import { ReactComponent as GirlIcon } from '../../assets/v3/avatar/girl-icon.svg';
import { ReactComponent as BoyIcon } from '../../assets/v3/avatar/boy-icon.svg';
import { ReactComponent as AvatarSample } from '../../assets/v3/avatar/avatar-sample.svg';
import { useState } from 'react';

const AvatarSelector = (props: { setAvatarId: Function }) => {
  const [selectedAvatarGender, setSelectedAvatarGender] = useState<string>('man');
  const [selectedAvatar, setSelectedAvatar] = useState<number>(0);

  const selectGender = (genderKey: string) => {
    if (genderKey !== selectedAvatarGender) {
      setSelectedAvatarGender(genderKey);
      setSelectedAvatar(0);
    }
  }

  return (
    <div className="ks-avatar-selector">
      <div className="ks-avatar-selector__title">Choisissez votre avatar</div>
      <div className="ks-avatar-selector__gender">
        <ManIcon className={`${selectedAvatarGender === 'man' && 'selected'}`} onClick={() => selectGender('man')} />
        <WomanIcon className={`${selectedAvatarGender === 'woman' && 'selected'}`} onClick={() => selectGender('woman')} />
        <GirlIcon className={`${selectedAvatarGender === 'girl' && 'selected'}`} onClick={() => selectGender('girl')} />
        <BoyIcon className={`${selectedAvatarGender === 'boy' && 'selected'}`} onClick={() => selectGender('boy')} />
      </div>
      <div className="ks-avatar-selector__list">
        {Array(48).fill(1).map((one: number, index: number) => (
          <AvatarSample key={index} className={`${selectedAvatar === index && 'selected'}`} onClick={() => {
            setSelectedAvatar(index);
            props.setAvatarId('images/man_avatar-sample.svg')
          }} />
        ))}
      </div>
    </div>
  );
}
export default AvatarSelector;