import React, { useContext, useEffect, useState } from 'react';
import { ReactComponent as HoopowLogo } from '../../assets/hoopow-logo.svg';
import { ReactComponent as IconEmail } from '../../assets/v2/menu_icon_email.svg';
import { ReactComponent as IconTelegram } from '../../assets/v2/menu_icon_telegram.svg';
import { ReactComponent as IconLinkedin } from '../../assets/v2/menu_icon_linkedin.svg';
import './Menu.scss';
import { Auth } from 'aws-amplify';
import { UserContext } from '../utils/UserContext';
import { NavLink, useHistory } from 'react-router-dom';

function Menu(props: { menuOpen?: boolean, isAuthenticated: boolean }) {
	const [menuOpen, setMenuOpen] = useState<boolean | undefined>(props.menuOpen);
	const [userRoot, setRootUser] = useState<any>();
	const history = useHistory();

	const { userInfo, isUserAuthenticated } = useContext(UserContext);

	const logout = async () => {
		await Auth.signOut();
		history.push('/');
	}

	useEffect(() => {
		if (isUserAuthenticated && userInfo) {
			setRootUser({
				name: userInfo.attributes.name,
				email: userInfo.attributes.email,
			});
		} else {
			setRootUser(undefined);
		}
	}, [userInfo, isUserAuthenticated]);

	return (
		<>
			<div className={`hp-menu-header`}>
				<div className={`hp-menu-nav-frame ks-clickable`} onClick={() => setMenuOpen(prev => !prev)} >
					<div className={`hp-menu-nav-icon ${menuOpen && 'open'}`} >
						<span></span>
						<span></span>
						<span></span>
						<span></span>
					</div>
				</div>
				<NavLink to={`/`} activeClassName='btnSelected ks-clickable'><HoopowLogo className='hp-menu-logo' /></NavLink>
			</div>
			<div className={`hp-menu-frame ${menuOpen && 'open'}`}>
				<div className={`hp-menu-frame-btn hp-menu-frame-member`}>
					{userRoot
						? (<>
							<div className='mainBtn'>{userRoot.name}</div>
							<NavLink to={`/member`} activeClassName='btnSelected' className={`mainBtn`}>MON ESPACE MEMBRE</NavLink>
						</>)
						: (
							<>
								<NavLink to={`${process.env.REACT_APP_FEATURE_FREEMIUM === '1' ? '/subscribe' : '/member?showPlans=1'}`} activeClassName='btnSelected' className='mainBtn'>S’ABONNER</NavLink>
								<NavLink to={`${process.env.REACT_APP_FEATURE_FREEMIUM === '1' ? '/signin' : '/login'}`} activeClassName='btnSelected' className={`mainBtn`}>SE CONNECTER</NavLink>
							</>
						)}
					<NavLink to={`/giftspace`} activeClassName='btnSelected' className='mainBtn'>OFFRIR UN ABONNEMENT</NavLink>
				</div>
				<div className={`hp-menu-frame-btn hp-menu-frame-contact`}>
					<NavLink to={`/contactus`} activeClassName='btnSelected' className='btnWithIcon'><IconEmail /><span>NOUS<br />CONTACTER</span></NavLink>
					<a href='https://t.me/muslimshow_officiel' className='btnWithIcon'><IconTelegram /><span>NOUS SUIVRE<br />SUR TELEGRAM</span></a>
					<a href='https://www.linkedin.com/company/hoopow/' className='btnWithIcon'><IconLinkedin /><span>NOUS SUIVRE<br />SUR LINKEDIN</span></a>
				</div>
				<div className={`hp-menu-frame-btn hp-menu-frame-member`}>
					<NavLink to={`/cguconnected`} activeClassName='btnSelected' className='mainBtn'>CONDITIONS D’UTILISATION</NavLink>
					{userRoot && userRoot.name !== 'Erreur' && (
						<div onClick={logout} className='mainBtn logout'>SE DÉCONNECTER</div>
					)}
				</div>
			</div>
		</>
	);
}

export default Menu;
