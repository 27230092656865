import './CheckoutForm.scss'
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement
} from "@stripe/react-stripe-js";
import Stripe, { PaymentMethodResult, StripeCardNumberElementOptions } from "@stripe/stripe-js";
import BaseDS from "../../datastore/BaseDS";
import { ClipLoader } from 'react-spinners';
import { ReactComponent as EmailIcon } from '../../assets/v3/email-icon.svg';
import { ReactComponent as CBIcon } from '../../assets/v3/cb-icon.svg';
import { ReactComponent as CVCIcon } from '../../assets/v3/cvc-icon.svg';
import { ReactComponent as CalendarIcon } from '../../assets/v3/calendar-icon.svg';
import { ReactComponent as WarningIcon } from '../../assets/v3/warning-icon.svg';
import { ReactComponent as StripeLogo } from '../../assets/v3/stripe-logo.svg';
import { ReactComponent as CBIcons } from '../../assets/v3/cb-icons.svg';
import Checkbox from './Checkbox';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import useResponsiveSize from '../utils/useResponsiveSize';
import SuccessfulPayment from './SuccessfulPayment';
import { UserContext } from '../utils/UserContext';

const pricingTypeToText: any = {
  recurring: "ABONNEMENT DE",
  one_time: "PAIEMENT DE"
}

const useOptions = () => {
  const fontSize = useResponsiveSize(20);
  const options: StripeCardNumberElementOptions = useMemo<StripeCardNumberElementOptions>(() => ({
    style: {
      base: {
        fontSize: fontSize + 'px',
        color: "black",
        fontFamily: "Avenir",
        fontWeight: "400",
        "::placeholder": {
          color: "#aab7c4"
        }
      },
      invalid: {
        color: "#BF594C"
      }
    }
  }), [fontSize]);

  return options;
};

const CheckoutForm = (props: { productId: string, itemId: string }) => {
  const stripe = useStripe();
  const elements = useElements();
  const elementsRef: any = useRef({});
  const [email, setEmail] = useState<string>('');
  const [pricingText, setPricingText] = useState<string>();
  const [price, setPrice] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [isSuccessPayment, setIsSuccessPayment] = useState<boolean>(false);
  const { productId, itemId } = props;
  const options: StripeCardNumberElementOptions = useOptions();
  const { userInfo } = useContext(UserContext);

  const loaderSize = useResponsiveSize(30);

  useEffect(() => {
    BaseDS.getProductDetailsById(productId).then((productDetails) => {
      setPricingText(pricingTypeToText[productDetails.type]);
      setPrice(productDetails.unit_amount / 100 + "€");
    });
  }, [productId])

  useEffect(() => {
    setEmail(userInfo?.attributes && userInfo.attributes.email);
  }, [userInfo])

  const handleInputChange = (e: any) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    displayError('');
    setLoading(true);

    if (!stripe || !elements || !elements.getElement(CardNumberElement)) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }
    const cardNumberElementObject: any = elements.getElement(CardNumberElement);

    const paymentMethod: PaymentMethodResult = await stripe.createPaymentMethod({
      type: 'card',
      card: cardNumberElementObject
    });
    if (paymentMethod.paymentMethod) {
      try {
        const paymentIntent = await BaseDS.createPurchase(productId, itemId, email, paymentMethod.paymentMethod?.id);
        await handlePaymentThatRequiresCustomerAction(paymentIntent, paymentMethod.paymentMethod?.id);
      } catch (error: any) {
        displayError({ error: { message: "Erreur durant la transaction." } });
        setLoading(false);
      }
    } else {
      displayError(paymentMethod);
      setLoading(false);
    }

  };

  const hasError = (cardElement: any) => {
    if (cardElement.error) {
      elementsRef.current[cardElement.elementType].classList.add("invalid");
    } else {
      elementsRef.current[cardElement.elementType].classList.remove("invalid");
    }
  }

  function displayError(event: any) {
    if (event.error) {
      setErrorMessage(event.error.message);
    } else {
      setErrorMessage('');
    }
  }

  const handlePaymentThatRequiresCustomerAction = async (paymentIntent: Stripe.PaymentIntent, paymentMethodId: string) => {
    if (
      paymentIntent.status === 'requires_action' ||
      paymentIntent.status === 'requires_payment_method'
    ) {
      return stripe?.confirmCardPayment(paymentIntent.client_secret || 'no_client_secret', {
        payment_method: paymentMethodId,
      })
        .then(async (result) => {
          if (result.error) {
            alert(result.error)
            throw result;
          } else {
            if (result.paymentIntent.status === 'succeeded') {
              await BaseDS.verifyPurchase(productId, itemId);
              setIsSuccessPayment(true);
            }
          }
        })
        .catch((error) => {
          displayError(error);
        }).finally(() => setLoading(false));
    } else {
      await BaseDS.verifyPurchase(productId, itemId);
      setLoading(false);
      setIsSuccessPayment(true);
    }
  }

  return (
    <div className="hp-checkoutWrapper">
      <div className="hp-checkoutPricing">
        {pricingText}&nbsp;<b>{price}</b>&nbsp;<CBIcons />
      </div>
      <div className={"card-element-errors " + (!errorMessage ? "empty" : "")} role="alert">{errorMessage}</div>
      {!isSuccessPayment ? (
        <form onSubmit={handleSubmit} className='hp-checkoutForm'>
          <label>
            <span>Email</span>
            <div className='field'>
              <EmailIcon className="fieldIcon email" />
              <input type='email' placeholder="Tapez ici votre email" className="inputForm" value={email || ''} onChange={handleInputChange} />
            </div>
          </label>
          <label>
            <span>Numéro de carte</span>
            <div className='field' ref={ref => elementsRef.current["cardNumber"] = ref}>
              <CBIcon className="fieldIcon cb" />
              <CardNumberElement options={options} className="inputForm" onChange={hasError} />
              <WarningIcon className="warning-icon" />
            </div>
          </label>
          <div className='grouped-field'>
            <label className='left'>
              <span>Date d’expiration</span>
              <div className='field' ref={ref => elementsRef.current["cardExpiry"] = ref}>
                <CalendarIcon className="fieldIcon calendar" />
                <CardExpiryElement options={options} className="inputForm" onChange={hasError} />
                <WarningIcon className="warning-icon" />
              </div>
            </label>
            <label className='right'>
              <span>Numéro de sécurité</span>
              <div className='field' ref={ref => elementsRef.current["cardCvc"] = ref}>
                <CVCIcon className="fieldIcon cvc" />
                <CardCvcElement options={options} className="inputForm" onChange={hasError} />
                <WarningIcon className="warning-icon" />
              </div>
            </label>
          </div>
          <Checkbox label='Mémoriser cette carte' />
          <button type="submit" disabled={!stripe} className={`${loading && 'disabled'}`}>
            {loading ? (<ClipLoader size={loaderSize} color="white" />) : (<>PAYER</>)}
          </button>
          <div className='stripe-logo'>Paiement sécurisé par&nbsp;<StripeLogo /></div>
        </form>
      ) : (<SuccessfulPayment action={() => {
        if (window.parent) {
          window.parent.postMessage({ 'step': 'payment_done_successfully' }, "http://localhost:3021");
        }
      }} />)}
    </div>
  );
};

export default CheckoutForm;
