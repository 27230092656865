import { useState } from "react";
import { Auth } from 'aws-amplify';
import './SignupFormV3.scss'
import AvatarSelector from "./AvatarSelector";

const SignUpFormV3 = (props: any) => {
  const [loading, setLoading] = useState(false);
  const [avatarId, setAvatarId] = useState<string>('man_avatar-sample.svg');
  const urlParams = new URLSearchParams(props.location.search);
  const sessionId: any = urlParams.get('session_id');

  const [formData, setFormData] = useState({
    email: '',
    name: '',
    avatarId: avatarId,
    password: '',
    repassword: '',
    codepromo: sessionId ? sessionId : ''
  });
  const handleInputChange = (e: any, prop: string) => {
    setFormData({
      ...formData,
      [prop]: e.target.value
    });
  };

  const submitSignUp = async () => {
    setLoading(true);
    try {
      const params: any = {
        username: formData.email.trim(),
        password: formData.password,
        attributes: {
          email: formData.email.trim(),
          name: formData.name.trim(),
          'custom:avatarId': avatarId.trim()
        }
      }
      await Auth.signUp(params);
      await Auth.signIn(formData.email.trim(), formData.password);
      if (window.parent) {
        window.parent.postMessage({ 'step': 'signedin' }, "http://localhost:3021");
      }
      setLoading(true);
    }
    catch (error: any) {
      if (error.code && error.code === 'UsernameExistsException') {
        alert('Cette adresse e-mail est déjà utilisée. N’hésitez pas à cliquer sur Mot de passe oublié en cas de besoin.');
      } else if (error.code && (error.code === 'InvalidPasswordException' || error.code === 'InvalidParameterException')) {
        alert('Votre mot de passe doit contenir au minimum 8 caractères, à savoir : au moins une lettre minuscule et une lettre majuscule, un caractère spécial et un chiffre.');
      } else {
        alert("Unknown error => " + error.message);
      }
      console.log('error Loading');
      setLoading(false);
    }
  }

  const isValidForm = () => {
    return !(formData.name === '' ||
      formData.email === '' ||
      formData.password === '' ||
      formData.avatarId === '' ||
      formData.repassword === '' ||
      formData.password !== formData.repassword)
  }

  return (
    <div className="ks-loginV3">
      <div className="ks-form-left">
        <div className="ks-form-title">S’INSCRIRE | SE CONNECTER</div>
        <form className='ks-form'>
          <div className="ks-form-group">
            <label className="ks-form-label">Email :</label>
            <input type='email' placeholder="Tapez ici votre email" onChange={(e) => handleInputChange(e, 'email')}
              className="ks-form-control"
            />
          </div>
          <div className="ks-form-group">
            <label className="form-label">Nom d'utilisateur :</label>
            <input type='text' placeholder="Tapez ici votre nom" onChange={(e) => handleInputChange(e, 'name')}
              className="ks-form-control"
            />
          </div>
          <div className="ks-form-group">
            <label className="ks-form-label">Mot de passe :</label>
            <input type="password" placeholder="Tapez ici votre mot de passe" onChange={(e) => handleInputChange(e, 'password')} className="ks-form-control" />
          </div>
          <div className="ks-form-group">
            <label className="ks-form-label">Confirmation du mot de passe :</label>
            <input type="password" placeholder="Confirmez ici votre mot de passe" onChange={(e) => handleInputChange(e, 'repassword')} className="ks-form-control" />
          </div>
        </form>
        <div className="ks-formBtn">
          <button className="mainBtn" onClick={() => { submitSignUp() }} type="submit" disabled={!isValidForm() || loading}>
            VALIDER
          </button>
        </div>
      </div>
      <div className="ks-form-right">
        <AvatarSelector setAvatarId={setAvatarId} />
      </div>
    </div>
  );
}
export default SignUpFormV3;