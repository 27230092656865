import { useContext, useEffect } from 'react';
import SVG from '../elements/SVG';
import { MainConfigContext } from '../utils/MainConfigContext';
import Feed from './Feed';
import './LayoutV2.scss';
import Menu from './Menu';

function LayoutV2(props: { children: any, className?: string, menuOpen?: boolean, isAuthenticated: boolean, location: any }) {

  const { mainConfig } = useContext(MainConfigContext);

  useEffect(() => {
    document.body.classList.remove('hp-body-homepage-notconnected-cgu');
    document.body.classList.remove('hp-body-homepage-notconnected');
  }, []);

  return (<>
    <div className={`ks-mainFrame ${props.className || ''} ${process.env.REACT_APP_FEATURE_NEW_DESIGN_V3 === '1' && 'hp-v3'} hp-layoutV2`}>
      <div id='hp-headerArea' className='hp-headerArea'>
        <Menu menuOpen={props.menuOpen} isAuthenticated={props.isAuthenticated} />
      </div>
      <div id='hp-leftArea' className='hp-leftArea'>
        <div className='hp-freemium-banner ks-clickable'>
          {mainConfig && process.env.REACT_APP_FEATURE_FREEMIUM === '1' && (
            <SVG src={mainConfig.supportUs} className='hp-freemium-banner-bkp' />
          )}
        </div>
        <Feed />
      </div>
      <div id='mainContentScreen' className='ks-content'>
        {props.children}
      </div>
    </div>
  </>);
}

export default LayoutV2;
