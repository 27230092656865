import { Storage } from 'aws-amplify';

export default class FileDS {

  static getSecuredUrlFile(key: string, callback: Function, size?: string | number) {
    Storage.get(key, {
      customPrefix: {
        public: ''
      },
    }).then((url: any) => {
      callback(url)
    });
  }

  static getUrlFile = (key: string, callback: Function, size?: string | number) => {
    callback(process.env.REACT_APP_IMAGE_API + key + (size ? "?tr=w-" + size : ''));
  };

  static getFileUrl(key: string, callback: Function, size?: string | number) {
    if (process.env.REACT_APP_IMAGE_API) {
      return FileDS.getUrlFile(key, callback, size);
    } else {
      return FileDS.getSecuredUrlFile(key, callback);
    }
  }

}

