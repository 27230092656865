import React, { useEffect, useRef, useState } from 'react';
import Slide from '../elements/common/slider/Slide';
import Slider from '../elements/common/slider/Slider';
import EidGiftCardTemplate from '../elements/EidGiftCardTemplate';
import { ReactComponent as BtnDownload } from '../../assets/download_btn.svg';
import './EidGiftCoupon.scss';
import { ClipLoader } from 'react-spinners';
import jsPDF from "jspdf";
import html2canvas from 'html2canvas';
import BaseDS from '../../datastore/BaseDS';

function EidGift(props: { sessionId?: string, couponId?: string }) {
  const { sessionId, couponId } = props;
  const sliderWrapper = useRef<any>();
  const [coupons, setCoupons] = useState<any[]>([]);
  const [current, setCurrent] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    if (sessionId) {
      BaseDS.retrieveSession(sessionId).then((data) => {
        setCoupons(data);
      }).finally(() => setLoading(false));
    } else if (couponId) {
      BaseDS.retrieveCoupon(couponId).then((data) => {
        setCoupons(data);
      }).finally(() => setLoading(false));
    } else {
      BaseDS.retrieveAllCoupon().then((data) => {
        console.log(data.sort((a, b) => new Date(b.creationDate).getTime() - new Date(a.creationDate).getTime()))
        setCoupons(data.sort((a, b) => new Date(b.creationDate).getTime() - new Date(a.creationDate).getTime()));
      }).finally(() => setLoading(false));
    }
  }, [sessionId, couponId]);

  return (
    <>
      {loading ? (<div className='hp-eidgift-card-loader'><ClipLoader /></div>) : (
        <div className='hp-eidgift-card-wrapper'>
          {coupons && coupons.length ? (<>
            <Slider
              ref={sliderWrapper}
              length={coupons.length}
              setCurrent={setCurrent}
            >
              {coupons && coupons.map((coupon, index) => (
                <React.Fragment key={index}>
                  <Slide className={'hp-eidgift-card-image'}>
                    <EidGiftCardTemplate key={coupon.couponId} couponCode={coupon.couponId} planId={coupon.planId} eventType={coupon.eventType} />
                  </Slide>
                </React.Fragment>
              ))}
            </Slider>
            <BtnDownload className={`hp-eidgift-card-download ks-clickable ${isDownloading ? 'ks-disabled' : ''}`}
              onClick={() => {
                if (isDownloading) return;
                setIsDownloading(true);

                const toPrint: any = document.querySelector('#eidgift-card-' + coupons[current].couponId);

                html2canvas(toPrint, { scale: 3 }).then(canvas => {
                  let doc = new jsPDF('l', 'mm', 'a8');
                  doc.addImage(canvas.toDataURL('image/png'), 'PNG', 0, 0, 74, 52, undefined, 'FAST');
                  doc.save('coupon.pdf');
                  setIsDownloading(false);
                });
              }} />
          </>) : (
            <div className='hp-eidgift-card-empty'>
              <div className='hp-eidgift-card-empty-maintext'>
                VOUS N’AVEZ PAS DE CARTE CADEAU DISPONIBLE
              </div>
              <div className='hp-eidgift-card-subtext'>
                (En cas de problème, merci de nous envoyer un mail à <a href='mailto:BDouin@hoo-pow.com'>BDouin@hoo-pow.com</a>)
              </div>
            </div>)}
        </div>
      )
      }
    </>
  );
}

export default EidGift;
