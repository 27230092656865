import { Auth } from "aws-amplify";
import React, { createContext, useState } from "react";

import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';

export const UserContext = createContext<any>("");

const UserContextProvider = (props: any) => {
	const [authState, setAuthState] = useState<AuthState>();
	const [userInfo, setUserInfo] = useState<any>();
	const [userBlocked, setUserBlocked] = useState<any>(true);
	const [isUserAuthenticated, setIsUserAuthenticated] = useState<boolean>();

	React.useEffect(() => {
		if (authState === undefined) {
			Auth.currentAuthenticatedUser().then((data) => {
				setAuthState(AuthState.SignedIn);
				setUserInfo(data);
				setIsUserAuthenticated(true);
				const userGroup = userInfo?.signInUserSession.accessToken.payload['cognito:groups'];
        if (!userGroup || !userGroup.length || userGroup.indexOf('blocked') === -1) {
          setUserBlocked(false);
        }
			}).catch((error) => {
				console.log(error);
				setAuthState(AuthState.SignIn);
				setUserInfo(undefined)
				setIsUserAuthenticated(false);
				setUserBlocked(false);
			});
		}

		return onAuthUIStateChange((nextAuthState) => {
			setAuthState(nextAuthState);
			if (AuthState.SignedIn === nextAuthState) {
				Auth.currentUserInfo().then((data) => {
					setUserInfo(data);
					setIsUserAuthenticated(true);
				})
			} else {
				setUserInfo(undefined);
				setIsUserAuthenticated(false);
			}
		});
	}, [authState, userInfo]);

	return (
		<UserContext.Provider value={{ authState, isUserAuthenticated, userInfo, userBlocked }} >
			{props.children}
		</UserContext.Provider>
	);
};

export default UserContextProvider;
